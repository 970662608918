import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import YoutubePlayer from "./YoutubePlayer/YoutubePlayer";

const AvatarImg = styled(Img)`
  border: 5px #e8e8e8 solid;
  display: block;
  width: 100px;
  height: auto;
`;

const TimelineItem = ({ data, index, className, hasStarted, setHasStarted }) => (
  <div className={className}>
    <div className="timeline-item-content">
      <div className="view-preview-video">
        <YoutubePlayer
          videoId={data.media.url}
          index={index}
          hasStarted={hasStarted}
          setHasStarted={setHasStarted}
        ></YoutubePlayer>
      </div>
      <div className="eya-tour-desc">
        <div className="tour-avatar">
          <div className="avatar-image">
            <AvatarImg
              fluid={data.detail.avatar.childImageSharp.fluid}
            ></AvatarImg>
          </div>
        </div>
        <div className="tour-desc">
          <span className="tour-guide-name">{data.detail.tourguide_name}</span>
          <p>{data.detail.tourguide_details}</p>
          <p>
            <span className="tour-date">{data.title}</span><br/>
            <span className="tour-date">{data.date}</span>
          </p>
        </div>
      </div>
      <span className="circle" />
    </div>
  </div>
);

export default styled(TimelineItem)`
  display: flex;
  position: relative;
  margin: -38px 0 10px calc(5% + 32px);
  max-width: 90%;
  justify-content: flex-start;
  .timeline-item-content {
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 15px;
    position: relative;
    text-align: left;
    align-items: flex-start;
    justify-content: center;

    .view-preview-video {
      margin: 0 10px 40px 10px;
      iframe {
        margin-bottom: 0;
      }
    }
    .circle {
      background-color: #ff6b6b;
      border: 3px solid #ff6b6b;
      border-radius: 50%;
      position: absolute;
      width: 15px;
      height: 15px;
      z-index: 1;
      right: auto;
      left: -38px;
      transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin: 15px 0;
    }
  }
  :hover .circle {
    background: #fff;
    border: 3px solid #ff6b6b;
  }

  .timeline-item-content .eya-tour-desc {
    background: #f2f2f3;
    margin: 0 0 80px 75px;
    padding: 50px 30px 20px 0;

    .avatar-image {
      float: left;
      margin: 0 0 0 -40px;
    }
    .tour-desc {
      padding-left: 81px;
      .tour-guide-name {
        font-size: 30px;
        font-weight: 600;
        color: #333;
      }
      p {
        font-size: 16px;
        color: #58595b;
      }
      .tour-date {
        word-break: break-all;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    margin-top: 5px;
    margin-left: 60px;
    margin-right: 0;

    .timeline-item-content {
      flex-wrap: wrap;
    }
    .timeline-item-content .circle {
      left: -86px;
    }
    .timeline-item-content .eya-tour-desc {
      width: 100%;
      margin: 0 10px 40px 20px;
    }
  }

  @media only screen and (max-width: 480px) {
    margin: 0;
    .timeline-item-content {
      margin-bottom: 50px;
    }

    .timeline-item-content .view-preview-video {
      padding-top: 50px;
      margin: 0;
    }
    .timeline-item-content .eya-tour-desc {
      width: 100%;
      margin: 0;
      padding: 50px 0 20px 0;
      .avatar-image {
        float: none;
        margin: 0 0 0 40px;
      }
      .tour-desc {
        padding: 0 40px;
      }
    }
  }
`;
