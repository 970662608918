import React from "react";
import YTReady from "./YoutubeReady";

const VIDEO_SIZE = {
  L_SIZE: {
    width: 650,
    height: 300
  },
  M_SIZE: {
    width: 384,
    height: 228
  },
  S_SIZE: {
    width: 300,
    height: 178
  }
};

class YoutubePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initYTPlayerSize()
    };
  }
  componentDidMount() {
    YTReady.then(YT => {
      this.YT = window["YT"];
      this.reframed = false;
      this.player = new YT.Player(`player${this.props.index}`, {
        height: this.state.height,
        width: this.state.width,
        videoId: this.props.videoId,
        events: {
          'onReady': this.onPlayerReady.bind(this),
        }
      });
    });
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  initYTPlayerSize = () => {
    let width = 0;
    if (typeof window !== `undefined`) {
      width = window.outerWidth;
    }
    if (width >= 700 && width <= 1050) {
      return {
        size: "l",
        height: VIDEO_SIZE.L_SIZE.height,
        width: VIDEO_SIZE.L_SIZE.width
      };
    } else if (width <= 480) {
      return {
        size: "s",
        height: VIDEO_SIZE.S_SIZE.height,
        width: VIDEO_SIZE.S_SIZE.width
      };
    } else {
      return {
        size: "m",
        height: VIDEO_SIZE.M_SIZE.height,
        width: VIDEO_SIZE.M_SIZE.width
      };
    }
  };

  updateDimensions() {
    const width = window.outerWidth;

    if (width >= 700 && width <= 1050) {
      if (!this.state.size.includes("l")) {
        this.player.setSize(VIDEO_SIZE.L_SIZE.width, VIDEO_SIZE.L_SIZE.height);
        this.setState({
          size: "l",
          height: VIDEO_SIZE.L_SIZE.height,
          width: VIDEO_SIZE.L_SIZE.width
        });
      }
    } else if (width <= 480) {
      if (!this.state.size.includes("s")) {
        this.player.setSize(VIDEO_SIZE.S_SIZE.width, VIDEO_SIZE.S_SIZE.height);
        this.setState({
          size: "s",
          height: VIDEO_SIZE.S_SIZE.height,
          width: VIDEO_SIZE.S_SIZE.width
        });
      }
    } else {
      if (!this.state.size.includes("m")) {
        this.player.setSize(VIDEO_SIZE.M_SIZE.width, VIDEO_SIZE.M_SIZE.height);
        this.setState({
          size: "m",
          height: VIDEO_SIZE.M_SIZE.height,
          width: VIDEO_SIZE.M_SIZE.width
        });
      }
    }
  }
  onPlayerReady() {

    const player = document.getElementById(`player${this.props.index}`);
    if (!player) return;

    this.player.mute();

    player.addEventListener("mouseenter", () => {
      if (!this.props.hasStarted) {
        this.props.setHasStarted(true);
      }
      this.player.playVideo();
    });
    window.addEventListener("scroll", () => {
      if (!this.props.hasStarted) { return; }
      const SLIDE_RANGE = 160;
      const playerPosition = player.getBoundingClientRect().top + this.state.height / 2;
      const isInRange = playerPosition < window.innerHeight / 2 + SLIDE_RANGE
        && playerPosition > window.innerHeight / 2 - SLIDE_RANGE;
      if (isInRange) {
        this.player.playVideo();
      } else {
        if (this.player.getPlayerState() === 1) this.player.pauseVideo();
      }
    });
  }
  render() {
    return <div id={`player${this.props.index}`}></div>;
  }
}

export default YoutubePlayer;
