import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Timeline from "../components/Timeline";
import PageLayout from "../components/PageLayout";

function Tour(props) {
  useEffect(() => {
    let tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }, []);

  const allTours = () =>
    props.data.allTours.edges.map(edge => {
      return {
        title: edge.node.frontmatter.title,
        date: edge.node.frontmatter.date,
        media: edge.node.frontmatter.media[0],
        detail: edge.node.frontmatter.detail[0]
      };
    });

  return (
    <PageLayout title="TOUR" uppercaseTitle titleAnim>
      <div className={props.className}>
        <div className="main-body">
          <div className="text-area">
            <Timeline data={allTours()} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Tour;

export const query = graphql`
  query {
    allTours: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(/tour/)/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            media {
              category
              url
            }
            detail {
              avatar {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              tourguide_name
              tourguide_details
            }
          }
        }
      }
    }
  }
`;
