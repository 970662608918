import React, { useState } from 'react';
import styled from 'styled-components'
import TimelineItem from './TimelineItem';

const renderItems = (data, hasStarted, setHasStarted) => {

    let renderArray = [];
    const map = new Map();

    data.forEach( item => {
        const key = item.date.slice(0,4);
        const collection = map.get(key);
        if(!collection) {
            map.set(key, [item]);
        }else{
            collection.push(item);
        }
    });

    map.forEach((value, key) =>{
        const collections = value;
        const renderItem = (
            <div key={key} className="year">
                <h3>{key}</h3>
                {collections.map((data, index) => {
                    return <TimelineItem 
                        key={index}
                        data={data}
                        index={key + index}
                        hasStarted={hasStarted}
                        setHasStarted={setHasStarted}
                    />
                })}
            </div>);
        renderArray.push(renderItem);
    });

    return renderArray.map(v=>v);
} 

const Timeline = ({className, data}) => {
    const [hasStarted, setHasStarted] = useState(false);
    return data.length > 0 && (
        <div className={className}>
            <div className="timeline-container">
                {renderItems(data, hasStarted, setHasStarted)}
            </div>
        </div>
    );
}

export default styled(Timeline)`
.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: -10px auto;
    max-width: 1400px;
    .year{
      position: relative;
    }
    h3{
      position: sticky;
      top: 100px;
      margin-left: -20px;
      margin-bottom: 0;
      color: #768390;
      font-weight: 400;
      z-index: 1;
    }
  }
  
  .timeline-container::before {
    background-color: #CCD5DB;
    content: "";
    z-index:-1;
    position: absolute;
    left: 5%;
    width: 4px;
    height: 100%;
  }
  
  @media only screen and (max-width: 1050px){
    .timeline-container::before{
        left: -20px;
    }
    .timeline-container h3{
      position: absolute;
      left: 6px;
      margin-left: 0;
      top: 12px;
    }
  }

  @media only screen and (max-width: 480px){
    .timeline-container{
        margin: 20px auto;
    } 
    .timeline-container::before{
        display: none;
    }
    .timeline-container h3{
        left: 40%;
        top: -10px;
    }
  }
`;